<template lang="pug">
.modal(
  :class="classes"
  @click.self="close"
)
  transition(:name="['center', 'top'].includes(position) ? 'modal' : 'slide-left'")
    .modal-card(v-if="value")
      .modal-header
        .modal-header-inner
          slot(name="title")
        i.fal.fa-times.modal-closeicon(@click="close")
      .modal-body
        slot(name="body")
      .modal-footer(v-if="primaryButton || secondaryButton")
        button.button(
          v-if="secondaryButton"
          :class="secondaryButton.class"
          @click="close"
        )
          span {{ secondaryButton.text }}
        button.button(
          v-if="primaryButton"
          class="icon-only"
          :class="primaryButton.class"
          :disabled="isLoading || primaryButton.disabled"
          @click="submit"
        )
          span(v-if="!isLoading") {{ primaryButton.text }}
          i.fas.fa-circle-notch.fa-spin(v-else)
</template>

<script>
export default {
  name: 'Modal',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    primaryButton: {
      type: [Object, Boolean],
      default: () => ({
        class: 'primary',
        text: 'Doorgaan',
      }),
    },
    secondaryButton: {
      type: [Object, Boolean],
      default: () => ({
        class: 'plain',
        text: 'Annuleren',
      }),
    },
    position: {
      type: String,
      default: 'center',
    },
    size: {
      type: String,
      default: 'small',
    },
    padding: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classes() {
      const active = this.value ? 'active' : '';
      const position = `position-${this.position}`;
      const size = `size-${this.size}`;
      const padding = this.padding ? 'has-padding' : '';
      const border = this.border ? 'has-border' : '';

      return `${active} ${position} ${size} ${padding} ${border}`;
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    submit() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 130px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  visibility: hidden;
  transition: $transition;

  &.active {
    z-index: 80;
    background-color: rgba($blackOlive, .6);
    visibility: visible;
  }

  &.position-right {
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0;

    .modal-card {
      height: 100%;
      max-height: unset;
      border-radius: 0;
    }
  }

  &.position-top {
    align-items: flex-start;

    .modal-card {
      margin-top: 80px;
    }
  }

  &.size-full {

    .modal-card {
      max-width: 100vw;
      height: calc(100vh - 200px);
    }
  }

  &.size-large {

    .modal-card {
      max-width: 1300px;
    }
  }

  &.size-small-medium {

    .modal-card {
      max-width: 600px;
    }
  }

  &.size-medium {

    .modal-card {
      max-width: 900px;
    }
  }

  &.has-padding {

    .modal-body, .modal-footer {
      padding: 25px 30px;
    }
  }

  &.has-border {

    .modal-header {
      border-bottom: 1px solid $champagne;
    }
  }

  &-card {
    position: relative;
    z-index: 90;
    overflow-y: auto;
    width: 100%;
    max-height: calc(100vh - 200px);
    max-width: 450px;
    padding: 0;
    border-radius: $radius-small;
    background: $white;
  }

  &-header {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 0 20px;
    color: $hunterGreen;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;

    &-inner {
      padding: 0 20px 0 0;
      box-sizing: border-box;
    }
  }

  &-closeicon {
    position: absolute;
    right: 15px;
    padding: 10px;
    color: $hunterGreen;
    font-size: 18px;
    cursor: pointer;
  }

  &-body {
    min-height: 50px;
    color: $rifleGreen;
    font-size: 14px;
    font-family: $fontDmSans;
    line-height: 22px;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media screen and (max-width: $breakpoint-md) {
    top: 60px;

    &.position-right .modal-card {
      height: calc(100% - 20px);
    }

    &.size-large {

      .modal-card {
        max-height: calc(100% - 50px);
        margin-top: 20px;
      }
    }

    &.has-padding {

      .modal-body, .modal-footer {
        padding: 20px 0;
      }
    }

    &-header {
      min-height: 40px;
      padding: 0;
      font-size: 18px;
    }

    &-closeicon {
      right: 0;
    }

    &-card {
      padding: 10px 20px;
    }
  }
}
</style>
